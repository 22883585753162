








































// This component is typically used as a dynamic component inside ModalWrapperComponent
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as utils_tracker from '../utils_tracker';
import * as utils_nft from '../utils_nft';
import { NFT } from '@/NFT'
import { NFTUser } from '@/NFTUser'
import * as utils from '../utils';
import hash from 'hash.js'

@Component({
  name: 'NFTSingle',
  components: { }
})
export default class NFTSingleComponent extends Vue {
  // prop reactive member variables publically available to the html template

  // public reactive member variables available to the html template
  public negativeButtonName: string = null;
  public nft: NFT = null

  public pieFilter: string = null   // to know if user is looking at his own pies
  public usingKitchen: boolean = false
  public editedName: string = null
  public editedDescription: string = null

  public preview: boolean = false             // query string parameter to enable preview features

  // public functions available to the html template
  public get loading(): boolean { return this.$store.state.loading }
  public get user(): NFTUser { return this.$store.getters.userObject }

  public setModalTitle      // hook into the ModalWrapperComponent's setModalTitle() function

  private audio: HTMLAudioElement = null

  public showKitchen(): boolean {
    // if (!this.preview) return false     // eventually comment out this line to enable the kitchen
    if (!utils_nft.isOctopie(this.nft)) return false
    if (utils_nft.isOctopie1of1(this.nft)) return false    // 1 of 1 pies are not editable
    return this.pieFilter === 'MINE' && this.user != null
  }

  public onCancel() {
    if (this.audio) this.audio.pause()
    this.$emit('close', null)
  }

  public onCancelEdit() {
    this.usingKitchen = false
  }

  public hasTransparentVersion(): boolean {
    return utils_nft.isOctopie(this.nft) && !utils_nft.isOctopie1of1(this.nft) && !this.nft.reserved && !this.nft.special
  }

  public getTransparentImageURL(): string {
    return utils_nft.convertToTransparentImageURL(this.nft.getImageURL())
  }

  public async onSaveEdit() {
    try {
      this.$store.commit('setLoading', { loading: true });

      const contractAddress: string = this.nft.contractAddress

      const url: string = utils.getURLprefix() + `/api/nfts/${contractAddress}/${this.nft.token_id}`;
      const metamaskToken: string = utils.reverseString(hash.sha1().update(this.user.address + utils.getAndSetUniqueClientID().toString()).digest('hex'))
      const putData: { metamaskToken: string, address: string, name: string, description: string } = { metamaskToken, address: this.user.address, name: this.editedName, description: this.editedDescription }
      const response: any = await utils.makePutCall(url, putData)
      utils.hideToasts(this)
      if (!response || !response.data) {
        utils.toast(this, 'Error', 'No response from server')
        return
      }
      if (response.data.error) utils.toast(this, 'Error', response.data.error)
      if (response.data.success) {
        utils.toast(this, 'Success', response.data.msg)
        this.nft.originalName = response.data.nft.originalName
        this.nft.metadata = response.data.nft.metadata
        this.setModalTitle(this.nft.metadata.name)
      }

    } catch (error) {
      if (error.toString().includes('Network Error') || error.toString().includes('status code 502')) utils.toast(this, 'Error', 'Undergoing Maintenance'); else utils.toast(this, 'Error', 'Could not load url')
      if (this.$store.state.devmode) console.log(error);
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }

    this.usingKitchen = false
  }

  public async onShow(dynamicProps: any) {
    if (dynamicProps) this.setModalTitle = dynamicProps.setModalTitle
    if (dynamicProps && dynamicProps.negativeButtonName) this.negativeButtonName = dynamicProps.negativeButtonName
    if (!this.negativeButtonName) this.negativeButtonName = 'Close'
    if (dynamicProps.nft) this.nft = dynamicProps.nft
    if (dynamicProps.pieFilter) this.pieFilter = dynamicProps.pieFilter

    // May 2, 2023: support music in animation_url
    if (this.nft.metadata.animation_url && (this.nft.metadata.animation_url.toLocaleLowerCase().endsWith('mp3') || this.nft.metadata.animation_url.toLocaleLowerCase().endsWith('wav'))) {
        if (this.audio) this.audio.pause()
        this.audio = new Audio(this.nft.metadata.animation_url);
        if (this.audio) this.audio.play();
    } 

  }

  public get showDescription(): boolean {
    return true
    return !this.nft.isMissingAttribute("Baker's Curation")
  }

  public async onShowKitchen() {
    let ownsOvens: boolean = false
    let errorMsg: string = null
    const thisSlice: string = utils_nft.getSlice(this.nft)

    // see if user owns any ovens by calling api
    try {
      const page: number = 1
      const contractAddress: string = utils_nft.PIELAND_OVENS_CONTRACT
      let url: string = utils.getURLprefix() + `/api/nfts/${contractAddress}`;
      url += `?page=${page}`;
      url += `&sort=TokenId_ASC`     // TokenId_ASC or RARITY_DESC
      url += `&ownerAddress=${this.user.address}`

      this.$store.commit('setLoading', { loading: true });

      const response: any = await utils.makeGetCall(url);
      if (response.data.error) {
        utils.toast(this, 'Error', `Could not load NFTs for ${contractAddress}`, 5000);
        return
      }

      // actual nfts
      let nfts: NFT[] = response.data.nfts
      nfts = nfts.map((po) => new NFT(po));

      // March 31, 2024: user must own an oven of this slice, but for the baked, we don't have
      //  ovens, so the requirement is that you must own one of every oven.
      // must own an oven of this slice
      if (thisSlice !== 'The Baked') {
        for (const nft of nfts) {
          if (utils_nft.getSlice(nft) === thisSlice) ownsOvens = true
        }
        if (!ownsOvens) errorMsg = `You need to own a ${thisSlice} Oven to use the Kitchen to edit your ${thisSlice} Octopie`
      } else {
        const ovenSlicesOwned: Set<string> = new Set<string>()
        for (const nft of nfts) {
          ovenSlicesOwned.add(utils_nft.getSlice(nft))
        }
        if (ovenSlicesOwned.size === 9) ownsOvens = true
        if (!ownsOvens) errorMsg = `You need to own an Oven from all 9 slices to use the Kitchen to edit your "The Baked" Octopie`
      }


    } catch (e) {
      utils.toast(this, 'Error', `Could not load ovens.`, 5000);
      console.error(e.toString())
    } finally {
      this.$store.commit("setLoading", { loading: false });
    }

    // show kitchen only if user owns ovens
    if (ownsOvens) {
      this.actuallyShowKitchen()
    } else {
      // show message that user needs to own ovens
      utils.toast(this, 'Error', errorMsg)
    }
  }
  

  // private, non-reactive member variables

  // private functions not available directly to HTML template
  private async mounted() { 
    if (this.$store.state.devmode) console.log(`${this.$options.name} mounted()`)
    if (this.$route.query.preview) this.preview = true

    utils_tracker.page(this.$options.name)
  }

  private actuallyShowKitchen() {
    // console.log(`onShowKitchen pieFilter=${this.pieFilter} and user=${this.user.address}`)
    this.usingKitchen = true

    // pre-populate
    this.editedName = this.nft.metadata.name
    this.editedDescription = this.nft.metadata.description
  }

  
  private async beforeDestroy() { 
    if (this.audio) this.audio.pause()
    if (this.$store.state.devmode) console.log(`${this.$options.name} beforeDestroy()`)
  }

}
