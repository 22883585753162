import { NFT, Metadata } from './NFT'

export const PIELAND_CONTRACT = '0xd3cD44f07744da3A6e60a4b5fdA1370400Ad515b'.toLocaleLowerCase()
export const PIELAND_OVENS_CONTRACT = '0xb38281fb31138Cc609511b3D83e404B783B33922'.toLocaleLowerCase()
export const PIELAND_MAP_CONTRACT = '0xb3E88f3f9c80b4De276b61bFee4B65806075d38c'.toLocaleLowerCase()
export const OPENSEA_ERC1155_CONTRACT = '0x2953399124f0cbb46d2cbacd8a89cf0599974963'.toLocaleLowerCase()

// Polygon token ids for OpenSea ERC1155 contract
export const PIEOAP_PIELIEVER = '97531751930509407484154395032144311111194252051252035787655567744335995732693'
export const PIEOAP_PIEONEER = '97531751930509407484154395032144311111194252051252035787655567745435507361444'
export const PIEOAP_FELLOWSHIP = '97531751930509407484154395032144311111194252051252035787655567746535018987615'
export const PIEOAP_GRAFFITI = '97531751930509407484154395032144311111194252051252035787655567747634530615421'
export const PIELAND_MAP_PIECE_1 = '97531751930509407484154395032144311111194252051252035787655567748734042243192'
export const PIELAND_MAP_PIECE_2 = '97531751930509407484154395032144311111194252051252035787655567749833553870983'
export const PIELAND_MAP_PIECE_3 = '97531751930509407484154395032144311111194252051252035787655567750933065498744'
export const PIELAND_MAP_PIECE_4 = '97531751930509407484154395032144311111194252051252035787655567752032577126510'

const PIELAND_ETH = '0xd7A103ed7ACa8673d275CD044D22AFc8b6eb8E6B'.toLocaleLowerCase()
const PIELAND_HOLDING_1 = '0x993108224220a984DBA107905fDe9eB3b81405d3'.toLocaleLowerCase()
const PIELAND_HOLDING_2 = '0x2f5c66D2f24F4Ebb43Af8B1e29FA04E0A72A61bC'.toLocaleLowerCase()

export const GEAUXT = '0xd9b927461Ac789147b1B79377BF5bBb885f1bcD3'.toLocaleLowerCase()
const SMURF88 = '0x81F958AD9c599aF6709f967c477756EdC94C5589'.toLocaleLowerCase()
const BENSON = '0x5DED9FdB4BD411B6783fDE38C55e85EF68228DfC'.toLocaleLowerCase()
const AUTUMN = '0x4457dA4b88ECdefE5999761E9A9189524F95c699'.toLocaleLowerCase()
const FISHIE = '0x1984bA1c0A42fAce6e1212a54D4830A84b36c210'.toLocaleLowerCase()
const SHAQ = '0x6df28a24C5628921Fe74d06bf1D53DB6eC4D9517'.toLocaleLowerCase()
const LIVETOKEN = '0xb764eC7628C1a2Ff3A74Eb60D61792c051D83531'.toLocaleLowerCase()

const DANIEL1 = '0x259ce2e7c5c2e239ff27be9cc4dbf8b73cad63ac'.toLocaleLowerCase()
const DANIEL2 = '0x6795474c42d4562e7242b042d4b91b86a3e96889'.toLocaleLowerCase()

const LUKE1 = '0x2c6819da2935bf006d34bb28f1dfdd14764ad6dd'.toLocaleLowerCase()
const LUKE2 = '0x7f9f9e95bf84c6528ba480867e678642b09774aa'.toLocaleLowerCase()
const LUKE3 = '0xf42935853fc5429e33c49685e7fe3a89edb02fe3'.toLocaleLowerCase()

const YY = '0xb2EB098889Ec469582aD5409C85034e03Af3657C'.toLocaleLowerCase()

const PIELAR = '0xdb0f86aCF09837d67387f78629D40D8BCa06508e'.toLocaleLowerCase()

const CLAYMORE = '0xA9A96577E7CBdbd6f3E21DEc2e4711d9023218F0'.toLocaleLowerCase()

const MAIN_SLICES = ['Doughbots', 'Expieriments', 'Pieborgs', 'Pielanders', 'Pierates', 'Piesces', 'Piesons', 'Vampiers', 'Zompies', 'The Baked', 'Burned']

export function isPielandAddress(address: string): string {
  address = address.toLocaleLowerCase()
  if (address === PIELAND_ETH) return 'Pieland.eth'
  if (address === PIELAND_HOLDING_1) return 'Holding 1'
  if (address === PIELAND_HOLDING_2) return 'Holding 2'
  return ''
}

export function isPielandAffiliatedAddress(address: string): string {
  if (!address) return null
  address = address.toLocaleLowerCase()
  if (isPielandAddress(address)) return isPielandAddress(address)

  // Bonfire
  if (address === GEAUXT) return 'Bonfire 1'
  if (address === SMURF88) return 'Bonfire 2'
  if (address === BENSON) return 'Bonfire 3'
  if (address === AUTUMN) return 'Bonfire 4'
  if (address === FISHIE) return 'Bonfire 5'
  if (address === SHAQ) return 'Bonfire 6'
  if (address === LIVETOKEN) return 'LiveToken'

  // Luke
  if (address === LUKE1) return 'Eldumbo 1'
  if (address === LUKE2) return 'Eldumbo 2'
  if (address === LUKE3) return 'Eldumbo 3'

  // Daniel
  if (address === DANIEL1) return 'Kelekin 1'
  if (address === DANIEL2) return 'Kelekin 2'

  if (address === YY) return 'YY'
  if (address === PIELAR) return 'Pielar'
  if (address === CLAYMORE) return 'Claymore'

  return ''
}

export function isOctopie(nft: NFT): boolean {
  return nft.contractAddress.toLocaleLowerCase() === PIELAND_CONTRACT
}

export function isOven(nft: NFT): boolean {
  return nft.contractAddress.toLocaleLowerCase() === PIELAND_OVENS_CONTRACT
}

export function isMap(nft: NFT): boolean {
  return nft.contractAddress.toLocaleLowerCase() === PIELAND_MAP_CONTRACT
}

export function isOctopie1of1(nft: NFT): boolean {
  if (!isOctopie(nft)) return false
  if (nft.getAttributeValues("Name") && nft.getAttributeValues("Name").length > 0) return true
  if (nft.getAttributeValues("Baker's Curation") && nft.getAttributeValues("Baker's Curation").length > 0) return true
  return false
}

export function isSlice(actualNFT: NFT, slice: string): boolean {
  if (actualNFT.getAttributeValues('Slice').includes(slice)) return true
  return false
}

export function getSlice(actualNFT: NFT): string {
  return actualNFT.getAttributeValues('Slice').toString()
}

export function isLeader(actualNFT: NFT): boolean {
  return (actualNFT.getAttributeValues("Baker's Curation").toString() === 'Leaders')
}

export function convertToTransparentImageURL(url: string): string {
  return url.replace('.jpg', '_noBg.png')
}





